import createReactComponent from '../createReactComponent';
export default createReactComponent('cart-heart', 'IconCartHeart', [
  ['path', { d: 'M4 19a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M10 17h-4v-14h-2', key: 'svg-1' }],
  ['path', { d: 'M6 5l14 1l-.717 5.016m-7.783 1.984h-5.5', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M18 22l3.35 -3.284a2.143 2.143 0 0 0 .005 -3.071a2.242 2.242 0 0 0 -3.129 -.006l-.224 .22l-.223 -.22a2.242 2.242 0 0 0 -3.128 -.006a2.143 2.143 0 0 0 -.006 3.071l3.355 3.296z',
      key: 'svg-3',
    },
  ],
]);
